.activo {
  border: 1px solid green;
  color: green;
  /*cambiar color*/
  border-radius: 10px;
  line-height: 1.85;

}

.inactivo {
  border: 1px solid red;
  color: red;
  border-radius: 10px;
  line-height: 1.85;
}

.viewLogic,
.viewMultimedia {
  margin-left: 20px;
  line-height: 1.85;
  margin-right: -15px;
}