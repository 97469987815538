.activo {
  border: 1px solid green;
  color: green;  /*cambiar color*/
  border-radius: 10px;
  
}

.inactivo {
  border: 1px solid red;
  color: red;
  border-radius: 10px;
}